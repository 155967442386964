<template>
  <div>
    <div class="hero hero--title">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">Liste des plugins</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="message is-info">
        <div class="message-header">Plugins du plan</div>
        <div class="message-body">
          <div class="columns is-multiline">
            <div class="column is-one-third" :key="`plan-plugin-${plugin.option}`" v-for="plugin in clusterPlanPlugins">
              <b-switch
                @input="v => onChangePlugin(plugin.option, v, plugin.from_date, plugin.to_date)"
                type="is-success"
                v-model="plugin.activated"
              >
                {{ PLUGIN_LABELS[plugin.option] }} {{ getDateLabel(plugin) }}
              </b-switch>
            </div>
          </div>
        </div>
        <div class="message-header">Plugins supplémentaires</div>
        <div class="message-body">
          <div class="columns is-multiline">
            <div class="column is-one-third" :key="`custom-plugin-${plugin.option}`" v-for="plugin in customPlugins">
              <b-switch
                @input="v => onChangePlugin(plugin.option, v, plugin.from_date, plugin.to_date)"
                type="is-success"
                v-model="plugin.activated"
              >
                {{ PLUGIN_LABELS[plugin.option] }} {{ getDateLabel(plugin) }}
              </b-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PluginWithDateRangeModal
      :cluster="cluster"
      :plugin="selectedPluginToUpdate"
      :isActive="isModalActive"
      :afterPluginUpdate="onUpdate"
      :onClose="() => (isModalActive = false)"
      :onCancel="plugin => cancelPluginUpdate(plugin) && (isModalActive = false)"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { getPlugins } from "Api/plugin";
import { SET_ERROR_ACTION, SET_SUCCESS_ACTION } from "Stores/message";
import {
  DO_NOT_IGNORE_PARCELS_PLUGIN,
  PHYTO_PLUGIN,
  RPG_ILOT_PLUGIN,
  LOCK_GEOLOCATION_PLUGIN,
  DISABLE_DRIVER_GEOLOC_PLUGIN,
  WORKED_AREA_PLUGIN,
  ROAD_SPEED_PLUGIN,
  PLUGIN_LABELS
} from "Constants";
import { formatToDate } from "Utils";
import { createClusterPlugin } from "Api/clusterPlugin";
import PluginWithDateRangeModal from "Components/clusters/clusterDetailsComponents/PluginWithDateRangeModal";

export default {
  props: ["cluster", "clusterPlan", "afterPluginUpdate"],
  components: { PluginWithDateRangeModal },
  computed: {
    clusterPlanPlugins() {
      const { clusterPlan, cluster, PLUGIN_LABELS } = this;
      if (!clusterPlan) {
        return [];
      }
      // if no label, it means the plugin is not activated
      return clusterPlan.plugins
        .filter(p => PLUGIN_LABELS[p])
        .map(plugin => {
          return cluster.plugins.find(cp => cp.option === plugin) || { option: plugin, activated: false };
        });
    },
    customPlugins() {
      const { cluster, clusterPlanPlugins, plugins, PLUGIN_LABELS } = this;
      if (!clusterPlanPlugins || !plugins) {
        return plugins;
      }
      let p = plugins
        .filter(plugin => {
          return PLUGIN_LABELS[plugin.name] && !clusterPlanPlugins.find(cp => cp.option === plugin.name);
        })
        .map(plugin => {
          return (
            (cluster.plugins && cluster.plugins.find(cp => cp.option === plugin.name)) || {
              option: plugin.name,
              activated: false
            }
          );
        });

      // Un plugin ne fait partie d'aucun plan
      let doNotIgnoreParcel = cluster.plugins.find(cp => cp.option === DO_NOT_IGNORE_PARCELS_PLUGIN);
      p.push({
        option: DO_NOT_IGNORE_PARCELS_PLUGIN,
        activated: doNotIgnoreParcel ? doNotIgnoreParcel.activated : false,
        from_date: doNotIgnoreParcel ? doNotIgnoreParcel.from_date : null,
        to_date: doNotIgnoreParcel ? doNotIgnoreParcel.to_date : null
      });
      let phyto = cluster.plugins.find(cp => cp.option === PHYTO_PLUGIN);
      p.push({
        option: PHYTO_PLUGIN,
        activated: phyto ? phyto.activated : false,
        from_date: phyto ? phyto.from_date : null,
        to_date: phyto ? phyto.to_date : null
      });
      let rpg_ilot_plugin = cluster.plugins.find(cp => cp.option === RPG_ILOT_PLUGIN);
      p.push({
        option: RPG_ILOT_PLUGIN,
        activated: rpg_ilot_plugin ? rpg_ilot_plugin.activated : false,
        from_date: rpg_ilot_plugin ? rpg_ilot_plugin.from_date : null,
        to_date: rpg_ilot_plugin ? rpg_ilot_plugin.to_date : null
      });
      let lock_geolocation_plugin = cluster.plugins.find(cp => cp.option === LOCK_GEOLOCATION_PLUGIN);
      p.push({
        option: LOCK_GEOLOCATION_PLUGIN,
        activated: lock_geolocation_plugin ? lock_geolocation_plugin.activated : false,
        from_date: lock_geolocation_plugin ? lock_geolocation_plugin.from_date : null,
        to_date: lock_geolocation_plugin ? lock_geolocation_plugin.to_date : null
      });
      let disable_driver_geoloc = cluster.plugins.find(cp => cp.option === DISABLE_DRIVER_GEOLOC_PLUGIN);
      p.push({
        option: DISABLE_DRIVER_GEOLOC_PLUGIN,
        activated: disable_driver_geoloc ? disable_driver_geoloc.activated : false,
        from_date: disable_driver_geoloc ? disable_driver_geoloc.from_date : null,
        to_date: disable_driver_geoloc ? disable_driver_geoloc.to_date : null
      });

      let worked_area = cluster.plugins.find(cp => cp.option === WORKED_AREA_PLUGIN);
      p.push({
        option: WORKED_AREA_PLUGIN,
        activated: worked_area ? worked_area.activated : false,
        from_date: worked_area ? worked_area.from_date : null,
        to_date: worked_area ? worked_area.to_date : null
      });

      let road_speed = cluster.plugins.find(cp => cp.option === ROAD_SPEED_PLUGIN);
      p.push({
        option: ROAD_SPEED_PLUGIN,
        activated: road_speed ? road_speed.activated : false,
        from_date: road_speed ? road_speed.from_date : null,
        to_date: road_speed ? road_speed.to_date : null
      });
      return p;
    }
  },
  methods: {
    ...mapActions({
      setError: SET_ERROR_ACTION,
      setSuccess: SET_SUCCESS_ACTION
    }),
    getDateLabel(plugin) {
      if (!plugin.from_date && !plugin.to_date) {
        return "";
      }
      if (plugin.activated) {
        if (plugin.from_date && !plugin.to_date) {
          return `[Depuis le ${formatToDate(new Date(plugin.from_date))}] `;
        }
        if (!plugin.from_date && plugin.to_date) {
          return `[Jusqu'au ${formatToDate(new Date(plugin.to_date))}]`;
        }
      } else {
        if (plugin.from_date && !plugin.to_date) {
          return `[Actif à partir du ${formatToDate(new Date(plugin.from_date))}] `;
        }
        if (!plugin.from_date && plugin.to_date) {
          return `[Actif jusqu'au ${formatToDate(new Date(plugin.to_date))}]`;
        }
      }

      let text = `Du ${formatToDate(new Date(plugin.from_date))} au ${formatToDate(new Date(plugin.to_date))}]`;

      if (!plugin.activated) {
        return `[Actif ${text}`;
      } else {
        return `[${text}`;
      }
    },
    cancelPluginUpdate(plugin) {
      this.cluster.plugins = this.cluster.plugins.map(p => {
        if (p.option === plugin.option) {
          p.activated = !p.activated;
        }
        return p;
      });
    },
    onUpdate(plugin) {
      this.isModalActive = false;
      const { cluster } = this;
      if (cluster.plugins.find(p => p.option === plugin.option)) {
        this.cluster.plugins = cluster.plugins.map(p => {
          if (p.option === plugin.option) {
            return plugin;
          }
          return p;
        });
      } else {
        this.cluster.plugins.push(plugin);
        this.cluster.plugins = [...this.cluster.plugins];
      }
    },
    onChangePlugin(plugin, activated, fromDate, toDate) {
      if (!activated) {
        const { apiClient } = this.$store.getters;
        const { cluster, onUpdate } = this;
        let data = {
          option: plugin,
          activated: false
        };

        createClusterPlugin(apiClient)(cluster.id, data)
          .then(({ data }) => onUpdate(data))
          .catch(e => {
            this.setError({
              message: "Une erreur est survenue lors de la desactivation du cluster_plugin",
              error: e
            });
          });

        return;
      }

      this.isModalActive = true;
      this.selectedPluginToUpdate = {
        option: plugin,
        activated,
        fromDate,
        toDate
      };
    },
    isPluginActivated(plugin) {
      const { cluster } = this;
      if (!cluster.plugins) {
        return false;
      }
      const clusterPlugin = cluster.plugins.find(p => p.option === plugin);
      if (!clusterPlugin) {
        return false;
      }
      return clusterPlugin.activated;
    }
  },
  mounted() {
    const { apiClient } = this.$store.getters;
    getPlugins(apiClient)()
      .then(({ data }) => {
        this.plugins = data;
      })
      .catch(e => {
        this.setError({
          message: "Une erreur est survenue pour charger la liste des plugins",
          error: e
        });
      });
  },
  data() {
    return {
      plugins: [],
      selectedPluginToUpdate: null,
      isModalActive: false,
      PLUGIN_LABELS
    };
  }
};
</script>
